.city-audioguids-card {
    position: relative;

    left: 16px;

    margin: 0 0 8px 0;

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;

    width: calc(100% - 32px);
    height: 104px;

    background: linear-gradient(275.78deg, #111827 0%, #1F2937 100%);

    border: 1px solid #1F2937;
    box-shadow: 0px 20px 32px rgba(17, 24, 39, 0.25), 0px 12px 12px rgba(17, 24, 39, 0.17);
}

.city-audioguids-cardClose {
    position: absolute;
    height: 16px;
    width: 16px;

    top: 8px;
    right: 8px;

    pointer-events: all;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.city-audioguids-cardContent {
    height: 100%;
    width: 100%;

    margin: 0 16px 0 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
}

.city-audioguids-cardContentTop {
    margin: 16px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.city-audioguids-cardImg {
    position: relative;
    width: 24px;
    height: 24px;

    background-image: url('../../style/assets/audioguid/headphones.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.city-audioguids-cardImg_F4C057{
    background-image: url('../../style/assets/audioguid/headphones_#F4C057.png');
}
.city-audioguids-cardImg_2BCEF0{
    background-image: url('../../style/assets/audioguid/headphones_#2BCEF0.png');
}
.city-audioguids-cardImg_F30C69{
    background-image: url('../../style/assets/audioguid/headphones_#F30C69.png');
}
.city-audioguids-cardImg_1DD761{
    background-image: url('../../style/assets/audioguid/headphones_#1DD761.png');
}

.city-audioguids-cardSubtitle {
    margin: 0 0 0 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    text-align: center;

    color: #FFFFFF;
}

.city-audioguids-cardTitle {
    margin: 0 0 18px 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.city-audioguids-cardIcon {
    height: 100%;
    width: 72px !important;

    margin: 0 0 0 16px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: all;
}

.city-audioguids-cardIconCircle {
    margin: 0 0 4px 0;
    box-sizing: border-box;

    width: 48px;
    height: 48px;

    border: 1px solid white;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.city-audioguids-cardIconCirclePlay {
    margin: 0 0 0 4px;

    height: 40%;
    width: 40%;

    background-image: url('../../style/assets/audioguid/playIcon.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.city-audioguids-cardIconCirclePause {
    margin: 0 0 0 0px;

    height: 60%;
    width: 60%;

    background-image: url('../../style/assets/audioguid/pauseIcon.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.city-audioguids-cardIconText {
    width: 100%;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    text-align: center;

    color: #FFFFFF;
}
.cookies {
    z-index: 200000;
    position: absolute;
    width: 100%;

    bottom: 0px;

    margin: 16px 0 0px 0;
    padding: 0 16px 0 16px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background: linear-gradient(116.36deg, #1F2937 0%, rgba(17, 24, 39, 0.9) 100%);

    border-bottom: 1px solid rgba(55, 65, 81, 0.5);
}

.cookies-title {
    width: calc(100% - 32px);
    margin: 16px 0 16px 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-family: 'Poppins';
    font-weight: 600;
    font-size: 24px;
    color: #F9FAFB;

    line-height: 32px;
}

.cookies-desc {
    width: calc(100% - 32px);
    margin: 16px 0 16px 0;

    /*display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;*/

    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    color: #F9FAFB;
    text-align: center;

    line-height: 24px;
}

.cookies-desc a, .cookies-desc a:visited, .cookies-desc a:hover, .cookies-desc a:active{
    color:#a1a1a1;
}

.cookies-buttons {
    width: calc(100% - 32px);

    margin: 0 0 24px 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cookies-button {
    width: min(calc(50% - 16px), calc(100vh * 325/1624 - 16px));

    margin: 0.6em 16px 0 16px;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    background: #F9FAFB;
    box-shadow: 0px 4px 0px #D1D5DB;
    border-radius: 1px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cookies-buttonText {
    height: 100%;

    right: 0%;
    margin: 14px 0 14px 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}
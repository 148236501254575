.popup {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 81000;

    top: -100%;

    pointer-events: none;

    transition: top 0.5s;
}

.popup-zone {
    position: absolute;
    width: calc(100% - 48px);

    top: 24px;
    left: 24px;

    background-color: rgba(250, 250, 250, 0.3);
    box-shadow: 0px 20px 32px rgba(17, 24, 39, 0.25), 0px 12px 12px rgba(17, 24, 39, 0.17);

    border-radius: 8px;
}

.popup-zoneInside {
    margin: 16px 24px 16px 24px;
}

.popup-zoneIcon {
    position: absolute;
    width: 18px;
    height: 18px;

    top: 12px;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    filter: invert();
}

.popup-zoneTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;

    display: flex;
    align-items: center;

    color: #111827;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.popup-zoneDesc {
    margin: 4px 0 0 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;

    color: #111827;

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
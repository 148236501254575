.menu {
	z-index: 100000;
	position: absolute;
	height: 60px;
	width: 100%;

	bottom: 0px;
	/* transform: translateY(180px); */
	background-color: #080D18;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	transition: all 2s;
	opacity: 0;
}

.menu-content {
	width: clamp(293px, 100%, 960px);
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.menu-button {
	height: 100%;
	width: 30%;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	pointer-events: all;
}

.menu-button-img {
	height: 24px;
	width: 100%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.menu-button-text {
	font-family: 'Poppins';
	font-weight: 600;
	font-size: 11px;
	color: #FFFFFF;
}

.glow {
	/* text-shadow: 0px 1px 4px rgba(255, 255, 255, 0.3); */

	filter: drop-shadow(0 0px 2px rgba(255, 255, 255, 0.3));
}

.unselect {
	opacity: 0.6
}
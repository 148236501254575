.city {
    z-index: 30000;
    position: absolute;
    height: 100%;
    width: 100%;

    top: 0px;
    left: 0px;

    background-color: #080D18;

    overflow-y: auto;
    overflow-x: hidden;
}

.city-title {
    width: 100%;

    margin: 44px 0 16px 24px;

    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: baseline;
}

.city-lang {
    position: absolute;
    height: 24px;

    margin: 44px 24px 16px 0;
    right: 0;
}

.city-selectArrow {
    position: relative;
    z-index: 50000;
    width: 1.8em;
    height: 1.8em;
    content: "";

    right: 0%;

    background: url("../../style/assets/downArrow.png") 96% / 15% no-repeat;
    background-size: contain;
    background-position: center;

    filter: invert(100%);
}


.city-langSelection {
    height: 16px;

    margin: 4px 0 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.city-langSelection__control {
    position: relative !important;
    width: 100% !important;

    border: 0px solid rgba(255, 255, 255, 0) !important;

    background-color: rgba(0, 0, 0, 0) !important;

    text-align: left !important;

    color: white !important;

    cursor: pointer !important;
}

.city-langSelection__indicator-separator {
    display: none !important;
}

.city-langSelection__value-container {
    left: 8px;
}

.city-langSelection__indicators {
    width: 2.4em;
}

.city-langSelection__indicator {
    position: absolute;
}

.city-langSelection__menu {
    margin: 8px 0 0 0;
    background-color: #111827 !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.city-langSelection__option {
    background-color: rgba(0, 0, 0, 0) !important;
    color: black !important;
}

.city-langOption {
    display: flex;
    flex-direction: row;

    font-size: clamp(0.8em, 2vw, 0.8em);

    color: black;

    justify-content: flex-start;
    align-items: center;
}

.city-langFlag {
    height: 16px;
    width: 32px;

    margin-right: 5%;
}

.city-langLang {
    margin: 0 0 0 5%;

    font-size: clamp(0.8em, 2vw, 5em);

    color: white;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    letter-spacing: 0.02em;
    text-transform: uppercase;
}


.city-placeSelection {
    height: 16px;

    margin: 4px 0 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    pointer-events: all;
}

.city-placeSelection__control {
    position: relative !important;
    width: 100% !important;
    min-height: 24px !important;
    height: 24px !important;

    border: 0px solid rgba(255, 255, 255, 0) !important;
    border-radius: 0 !important;

    text-align: left !important;

    color: #080D18 !important;

    cursor: pointer !important;
}

.city-placeSelection__indicator-separator {
    display: none !important;
}

.city-placeSelection__value-container {
    margin: 0 !important;
}

.city-placeSelection__indicators {
    width: 2.4em;
}

.city-placeSelection__indicator {
    position: absolute;

    filter: invert(100%)
}

.city-placeSelection__menu {
    position: absolute;
    width: fit-content !important;
    margin: 8px 0 0 0;
    background-color: #111827 !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.city-placeSelection__option {
    background-color: rgba(0, 0, 0, 0) !important;
    color: black !important;
}

.city-placeOption {
    display: flex;
    flex-direction: row;

    font-size: clamp(0.8em, 2vw, 0.8em);

    color: black;

    justify-content: flex-start;
    align-items: center;
}

.city-placePlace {
    color: #080D18 !important;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    letter-spacing: 0.02em;
    text-transform: uppercase;
}

.city-placeSelection__menu .city-placePlace {
    color: white !important;
}

.city-title-cityName {
    width: auto;
}

.city-title-cityNameContent {
    margin: 4px 8px;

    font-family: 'Poppins';
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 2%;
    color: #080D18;
    text-transform: uppercase;
}

.city-title-discover {
    margin: 4px 0 0 0;

    font-family: 'TungstenNarrow-Semibold';
    font-weight: 600;
    font-size: 64px;
    color: #F9FAFB;
    text-transform: uppercase;

    line-height: 54px;
}

.city-stories {
    width: 100%;

    margin: 16px 0 8px 0;
    padding: 0 0 0 24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background: linear-gradient(116.36deg, #1F2937 0%, rgba(17, 24, 39, 0) 100%);

    border-bottom: 1px solid rgba(55, 65, 81, 0.5);
}

.city-stories-title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #F9FAFB;
}

.city-stories-caroussel {
    position: relative;
    height: 100%;
    width: 100%;

    margin: 16px 0 16px -24px;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.city-stories-caroussel::-webkit-scrollbar { 
    display: none;
}

.city-stories-carousselContent {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.city-stories-caroussel-item {
    position: relative;
    height: 100%;
    width: 120px;
    margin: 0 0 0 24px;

    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.city-stories-caroussel-itemContent {
    height: 100%;
    width: 80%;

    border: 2px solid #1F2937;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.city-stories-caroussel-itemContentInside {
    aspect-ratio: 1/1;
    width: calc(100% - 8px);

    background-color: #1F2937;
    border-radius: 50%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.city-stories-caroussel-itemContentInsideIcon {
    aspect-ratio: 1/1;
    width: 30%;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.city-stories-caroussel-desc {
    width: calc(100% + 12px);

    margin: 8px 0 24px 0;

    font-family: 'Poppins';
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-align: center;
}

.city-audioguids-title {
    margin: 16px 0 0px 24px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 20px;

    color: #F9FAFB;
}

.city-audioguids-subtitle {
    margin: 12px 0 32px 24px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #D1D5DB;
}

.city-buildings {
    width: 100%;

    margin: 0px 0 32px 24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.city-buildings-title {
    margin: 24px 0 0 0;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #F9FAFB;
}

.city-buildings-caroussel {
    position: relative;
    height: 100%;
    width: 100%;

    margin: 16px 0 16px -24px;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.city-buildings-caroussel::-webkit-scrollbar { 
    display: none;
}

.city-buildings-carousselContent {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.city-buildings-caroussel-item {
    position: relative;
    height: 100%;
    width: 240px;
    margin: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.city-buildings-caroussel-itemContent {
    aspect-ratio: 1/1;
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.city-buildings-caroussel-itemContentInside {
    aspect-ratio: 2/3;
    width: 100%;

    background-color: #1F2937;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.city-buildings-caroussel-desc {
    position: absolute;

    left: 0%;

    bottom: 8px;

    margin: 4px 8px 4px 0;

    font-family: 'TungstenNarrow-Semibold';
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0.02em;
    color: #080D18;
    text-transform: uppercase;
}


.city-buildings3D {
    width: 100%;

    margin: 0px 0 16px 0;
    padding: 0 0 0 24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    background: linear-gradient(116.36deg, #1F2937 0%, rgba(17, 24, 39, 0) 100%);

    border-bottom: 1px solid rgba(55, 65, 81, 0.5);
}

.city-buildings3D-title {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.08em;
    color: #F9FAFB;
}

.city-buildings3D-caroussel {
    position: relative;
    width: calc(100%);

    margin: 16px 0 16px -24px;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.city-buildings3D-caroussel::-webkit-scrollbar { 
    display: none;
}

.city-buildings3D-carousselContent {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.city-buildings3D-caroussel-item {
    position: relative;
    height: 100%;
    width: 120px;
    margin: 0 0 0 24px;

    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.city-buildings3D-caroussel-itemContent {
    height: 100%;
    width: calc(90%);

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.city-buildings3D-caroussel-itemContentInside {
    aspect-ratio: 1/1;
    width: calc(100% - 8px);

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.city-buildings3D-caroussel-itemContentInsideIcon {
    aspect-ratio: 1/1;
    width: 30%;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.city-buildings3D-caroussel-desc {
    width: calc(100% + 12px);

    margin: 8px 0 24px 0;

    font-family: 'Poppins';
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-align: center;
}


.city-otherpois {
    width: 100%;

    margin: 0px 0 32px 24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.city-otherpois-title {
    font-family: 'TungstenNarrow-Semibold';
    font-weight: 600;
    font-size: 48px;
    letter-spacing: 0.02em;
    line-height: 40px;
    color: #F9FAFB;
    text-transform: uppercase;
}

.city-otherpois-tags {
    position: relative;
    width: 100%;

    padding: 0;

    margin: 16px 0 16px -24px;

    /* background-color: bisque; */
}

.city-otherpois-tagsContent {
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.city-otherpois-tag {
    margin: 0px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.city-otherpois-tagContent {
    margin-left: 12px;

    border-radius: 14px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border: 2px solid #1F2937;

    font-family: 'Poppins';

    background-color: #F9FAFB;
}

.city-otherpois-tagContentUnselected {
    margin-left: 12px;

    border-radius: 14px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border: 2px solid #1F2937;

    font-family: 'Poppins';
}

.city-otherpois-tagContentBrace {
    margin: 4px 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
}

.city-otherpois-tagContentName {
    margin: 8px 4px;

    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;

    white-space: nowrap;
}

.city-otherpois-tagContentNameUnselected {
    margin: 8px 4px;

    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;

    white-space: nowrap;
}

.city-otherpois-tagContentAmount {
    margin: 4px 8px;

    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-align: center;
}

.city-otherpois-images {
    width: calc(100% - 48px);

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
    grid-auto-rows: minmax(100px, auto);
}

.city-otherpois-poi {
    aspect-ratio: 1/1;
    width: 100%;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.city-quiz {
    position: relative;
    height: 200px;
    width: calc(100% - 32px);

    margin: 64px 16px 32px 16px;

    background: rgba(8, 13, 24, 0.96);
    background-repeat: no-repeat;
    background-size: cover;

    color: white
}

.city-quizTop {
    position: absolute;
    z-index: 93000;
    width: 100%;
    height: 100%;

    top: 0px;
    left: 0px;

    background-image: url('../../style/assets/quiz/border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.city-quizTopText {
    position: absolute;
    z-index: 93500;

    top: -16px;
    left: 0px;

}

.city-quizTopTextTitle {
    width: fit-content;

    padding: 0 4px 0 8px;

    font-family: 'TungstenNarrow-Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 32px;

    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #FFFFFF;

    border: 4px solid #080D18;
    background-color: #080D18;
}

.city-quizTopTextSubtitle {
    top: 48px;

    /*padding: 0 4px 0 8px;*/

    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;

    color: white;

    /*border: 4px solid #080D18;
    background-color: #080D18;*/
}

.city-quizButton {
    position: absolute;
    z-index: 94500;

    bottom: 16px;
    left: 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;

    background: #F9FAFB;
    box-shadow: 0px 4px 0px #D1D5DB;
    border-radius: 1px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    text-align: center;

    color: #080D18;
}

.quizPage-rightAnim {
    z-index: 95000;
    width: 60%;

    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'TungstenNarrow-Semibold';
    font-style: normal;
    font-weight: 373;
    font-size: 64px;
    line-height: 64px;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
}

.quizPage-wrongAnim {
    z-index: 95000;
    width: 60%;

    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: 'TungstenNarrow-Semibold';
    font-style: normal;
    font-weight: 373;
    font-size: 64px;
    line-height: 64px;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;
}
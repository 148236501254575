.introScreen {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 16000;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    background-color: #080B18;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    transition: all 1s;
}

.introScreen-image {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 80%;

    top: 12%;

    background-position: center center;
    background-size: contain ;
    background-repeat: no-repeat;

    pointer-events: all;
}

.introScreen-langSelection {
    z-index: 2;
    width: 90%;
    max-width: 800px;

    margin: 0 0 16px 0;

    color: white;
}

.introScreen-langSelectionContent {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;

    color: white;
}

.introScreen-langSelectionTitle {
    font-family: 'Poppins';
    font-size: clamp(0.8em, 50%, 1.2em);
    font-weight: 400;

    margin: 0 0 0 0;

    text-align: center;
}

.introScreen-langSelection-button {
    position: relative;
    width: min(calc(100% - 32px), calc(100vh * 750/1624 - 32px));
    height: 25%;

    margin: 0.6em 0 0 0;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    background-color: #1F2937;

    border: 1px solid #374151;

    box-shadow: 0px 4px 0px #1F2937;
    border-radius: 1px;
}

.introScreen-langSelection-buttonFlag {
    aspect-ratio: 1/1;
    width: 16px;

    margin: 0 1em 0 1em;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.introScreen-langSelection-buttonText {
    height: 100%;

    right: 0%;
    margin: 14px 14px 14px 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'Poppins';
    font-size: clamp(0.8em, 50%, 1.2em);
    letter-spacing: .05rem;
    font-weight: 700;
}

.introScreen-langSelection-buttonCheck {
    position: absolute;
    aspect-ratio: 1/1;
    height: 40%;

    right: 4%;

    background-image: url('../../style/assets/icons/check.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.introScreen-langSelectionContent-loading {
    position: absolute;
    width: 100%;
    height: 100%;

    top: 0%;
    left: 0%;

    transition: all 3s;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    color: white;
    opacity: 0;

    pointer-events: none;
}

.introScreen-langSelectionContent-loadingText {
    position: relative;
    top: 75%;

    left: 50%;
    transform: translateX(-50%);

    text-align: center;
}

.introScreen-langSelectionContent-loadingAmount {
    position: relative;
    width: 70%;
    height: 8px;

    top: calc(75% + 14px);
    margin-left: 50%;
    transform: translateX(-50%);

    background: #080D18;
    border: 1px solid #1F2937;
}

.introScreen-langSelectionContent-loadingAmountInside {
    position: absolute;
    top: 0%;
    left: 0%;

    height: calc(100% - 2px);

    margin: 0 0 0 0;

    border: 1px solid #1F2937;
}

.introScreen-download {
    width: min(80%, calc(100vh * 750/1624 * 0.8));
    height: 25%;

    margin: 1.2em 0 1.2em 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #1F2937;

    border: 2px solid #374151;

    box-shadow: 0px 3px #111827;
    /* width: 100%;

    padding: 0.2em;
    margin: 2em 0 2em 0;

    text-align: center;

    color: white;

    background-color: rgba(0, 0, 0, 0.8);

    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 800; */
}

.introScreen-download-buttonText {
    height: 100%;
    width: calc(100% - 28px);

    margin: 14px 14px 14px 14px;

    text-align: center;
    font-family: 'Poppins';
    font-size: clamp(0.8em, 50%, 1.2em);
    letter-spacing: .05rem;
    font-weight: 700;
}
.reticle {
	z-index: 9999;
	position: absolute;
	height: 4px;
	width: 4px;

	top: calc(50% - 2px);
	left: calc(50% - 2px);

	border-radius: 50%;

	background-color: white;
}

.filters {
	z-index: 25000;
	position: absolute;
	height: 130px;
	width: 100%;

	bottom: 60px;
	padding: 0 0 10px 0;
	/* transform: translateY(180px); */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	opacity: 0;

	transition: all 2s;

	background: rgb(8, 13, 24);
	background: linear-gradient(
		0deg,
		rgba(8, 13, 24, 1) 0%,
		rgba(8, 13, 23, 0.5970763305322129) 40%,
		rgba(8, 13, 23, 0) 100%
	);
}

.filters-title {
	position: absolute;
	top: 0px;

	font-family: "Poppins";
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 4px;
	color: white;

	text-transform: uppercase;
}

.filters-content {
	width: clamp(293px, 40%, 960px);
	height: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.filters-sphere {
	aspect-ratio: 1/1 !important;
	height: 100%;
	max-width: 120px;

	margin-top: 40px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.filters-sphere-img {
	aspect-ratio: 1/1 !important;
	width: 60%;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	border-radius: 50%;
	border-width: 2px !important;
	border-style: solid !important;
}

.filters-sphere-text {
	margin-bottom: 8px;

	font-family: "Poppins";
	font-weight: 600;
	font-size: 11px;
	letter-spacing: 0.5px;
	color: white;
}

#transition {
	transition: all 0.5s ease;
}

.interface {
	opacity: 0;
	transition: all 2s;
}

.buttonRight {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 8px;
	width: 48px;
	height: 48px;
	backdrop-filter: blur(5px);
	cursor: pointer;
	color: white;

	border: 1px solid rgba(255, 255, 255, 0.16);
	border-radius: 50%;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.poiClick {
	opacity: 0;
	transition: opacity 1s;
}

.poiClickVisible {
	opacity: 1;
}

.videoStoryClose {
	position: "absolute";
	z-index: 41000;

	height: 32px;
	width: 24px;

	margin: 16px;

	top: 0px;
	left: 0px;

	background-image: url("../../style/assets/icons/back.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 24px 24px;
}

.poiList {
	transition: all 2s;
}

.finger {
	opacity: 0;
	position: absolute;
	z-index: 80000;
	aspect-ratio: 1/1;
	width: 40%;

	margin-left: 30%;

	bottom: 200px;

	background-image: url(../../style/assets/fingerLeftRight.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	transition: opacity 1s;

	pointer-events: none;
}

.videoStory-container {
	position: absolute;
	height: 100%;
	width: 100%;

	z-index: 40000;
	background-color: #090d18;
}

.videoStory-top {
	position: absolute;
	z-index: 99000;

	top: 0px;

	height: 80px;
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
}

.videoStory-back {
	position: absolute;
	z-index: 120000;

	height: 32px;
	width: 24px;

	margin: 16px;

	background-image: url("../../style/assets/icons/back.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 24px 24px;
}

.audioguid {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 85000;

	top: -100%;

	pointer-events: none;

	transition: top 0.5s;
}

.promptAudioGuid {
	position: absolute;
	z-index: 98000;
	height: 100%;
	width: 100%;

	background: rgba(8, 13, 24, 0.8);
	backdrop-filter: blur(8px);

	pointer-events: all;

	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.promptAudioGuidText {
	width: calc(100% - 32px);

	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 28px;

	text-align: center;

	color: #ffffff;

	text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.24), 0px 0px 4px rgba(0, 0, 0, 0.16),
		0px 0px 1px rgba(0, 0, 0, 0.24);
}

.promptAudioGuidValidate {
	width: calc(100% - 32px);
	height: 48px;

	margin: 16px 0 0 0;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;

	text-align: center;

	color: #f9fafb;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: #1f2937;
	border: 1px solid #374151;
	box-shadow: 0px 4px 0px #1f2937;
	border-radius: 1px;

	pointer-events: all;
}

.promptAudioGuidCancel {
	width: calc(100% - 32px);
	height: 48px;

	margin: 16px 0 0 0;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;

	text-align: center;

	color: #f9fafb;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background: #1f2937;
	border: 1px solid #374151;
	box-shadow: 0px 4px 0px #1f2937;
	border-radius: 1px;

	pointer-events: all;
}

.ag-arrowLeft {
	position: absolute;
	width: 24px;
	height: 24px;

	top: 10px;
	left: 10px;

	background-image: url("../../style/assets/audioguid/left.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	opacity: 0;
}

.ag-arrowRight {
	position: absolute;
	width: 24px;
	height: 24px;

	top: 10px;
	right: 10px;

	background-image: url("../../style/assets/audioguid/right.png");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	opacity: 0;
}

.layerButton {
	margin: 6px 8px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 10px 6px 10px;

	height: 24px;

	border-radius: 16px;

	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 16px;

	text-align: center;
	letter-spacing: 0.5px;

	color: #ffffff;

	white-space: nowrap;
}

.unSelectedLayer {
	/* border: 0px !important; */
	backdrop-filter: blur(8px);
	background: rgba(255, 255, 255, 0.08) !important;
	color: black;
}

.landscapeDisabled {
	display: none;
}

@media screen and (orientation: portrait) {
	.landscapeDisabledMobile {
		display: none;
	}
}

@media screen and (orientation: landscape) {
	.landscapeDisabledMobile {
		display: flex;

		flex-direction: column;
		justify-content: center;
		align-items: center;

		position: absolute;
		z-index: 25100;
		width: 100%;
		height: 100%;

		background-color: rgb(31, 31, 31);

		color: white;

		font-family: "Poppins";
		font-weight: 600;
		font-size: 14px;
		color: #ffffff;
	}
}

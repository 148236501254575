.details-container{
    z-index: 30005;
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    text-align: center;
    background-color: #090d18;
    overflow-y: auto;
    overflow-x: hidden;
}

.details-menuTop-back {
    height: 32px;
    width: 24px;

    margin: 16px;

    background-image: url('../../style/assets/icons/back.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
}

.details-menuTop-locate {
    height: 32px;

    margin: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #1F2937;
}

.details-menuTop-locateIcon {
    height: 24px;
    width: 24px;

    margin: 16px 8px 16px 12px;

    background-image: url('../../style/assets/icons/find.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.details-menuTop-locateText {
    margin: 16px 8px;

    font-family: 'Poppins';
    font-weight: 600;
    font-size: 12px;
    color:#FFFFFF;

    line-height: 16px;
}

.details-content{
    height: 100%;
    width: 600px;
    margin:auto;
    color: white;
    text-align: left;
    overflow-y: auto;
    overflow-x: hidden;
}

.details-menuTop {
    position: relative;
    height: 80px;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.details-img{
    max-height: 75vh;
    width: 100%;
    aspect-ratio: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.details-content .details-title{
    position:relative;
    top: -28px;
    padding: 2px 16px;

    font-family: 'TungstenNarrow-Semibold';
    font-weight: 600;
    font-size: 50px;
    color:black;
    text-transform: uppercase;

    line-height: 50px;
}

.details-text {
    margin: 0px 16px 16px 16px;
}

.details-text h3 {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 19px;
    line-height: 20px;
    color: #F9FAFB;
}

.details-text p {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #D1D5DB;
}

.btnClose{
    cursor: pointer;
    margin: auto;
}

.details-complement {
    width: 100%;

    margin: 16px 0 16px 0;
    padding: 0 0 0 24px;

    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;

    background: linear-gradient(116.36deg, #1F2937 0%, rgba(17, 24, 39, 0) 100%);

    border-bottom: 1px solid rgba(55, 65, 81, 0.5);
}

.details-complementTitle {
    font-family: 'TungstenNarrow-Semibold';
    font-weight: 600;
    font-size: 48px;
    color:#FFFFFF;
    text-transform: uppercase;
}

.details-complementContent {
    width: calc(100% - 48px);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.details-complementContent-item {
    margin: 0px;
    width: 40%;

    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.details-complementContent-itemContent {
    aspect-ratio: 1/1;
    width: calc(90%);

    border: 2px solid #1F2937;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.details-complementContent-itemContentInside {
    aspect-ratio: 1/1;
    width: calc(100% - 8px);

    background-color:#1F2937;
    border-radius: 50%;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.details-complementContent-itemContentInsideIcon {
    aspect-ratio: 1/1;
    width: 30%;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.details-complementContent-desc {
    width: 80%;

    margin: 8px 0 24px 0;

    font-family: 'Poppins';
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    text-align: center;
}

@media (max-width: 700px){
    .details-content{
        width: 100%;
    }
}
.viewer3d {
    position: absolute;
    width: 100%;

    z-index: 40000;
    background-color: #090d18;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.viewer3Dloading {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: #090d18;
}

.viewer3DloadingText {
    position: relative;

    top: 50%;
    margin-left: 50%;
    transform: translateX(-50%);

    text-align: center;
}

.viewer3DloadingAmount {
    position: relative;
    width: 70%;
    height: 8px;

    top: calc(50% + 14px);
    margin-left: 50%;
    transform: translateX(-50%);

    background: #080D18;
    border: 1px solid #1F2937;
}

.viewer3DloadingAmountInside {
    position: absolute;
    top: 0%;
    left: 0%;

    width: 30%;
    height: calc(100% - 2px);

    margin: 0 0 0 0;

    border: 1px solid #1F2937;
}
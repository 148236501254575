.quizPage {
    position: absolute;
    z-index: 90000;
    height: calc(100% - 60px);
    width: 100%;

    top: 0px;
    left: 0px;

    background: rgba(8, 13, 24, 1);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow-y: auto;
}

.quizPage-prequiz {
    position: absolute;
    z-index: 91000;
    height: 100%;
    width: 100%;

    top: 0px;
    left: 0px;

    background: radial-gradient(104.55% 50% at 50% 50%, rgba(8, 13, 24, 0.71) 0%, rgba(8, 13, 24, 0.96) 100%);
    backdrop-filter: blur(8px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizPage-endquiz {
    padding: 32px 0 0 0;
    position: absolute;
    z-index: 90000;
    height: calc(100% - 60px);
    width: 100%;

    top: 0px;
    left: 0px;

    background: rgba(8, 13, 24, 1);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow-y: auto;
}

.quizPage-endQuizImg {
    width: 100%;
    height: fit-content;

    margin: 0 0 30vh 0;

    background-position: center 40%;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizPage-prequiz-top {
    position: absolute;
    width: 100%;
    height: 30%;

    top: 0%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizPage-prequiz-title {
    max-width: 80%;

    padding: 0 0 4px 0;

    font-family: 'TungstenNarrow-Semibold';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 60px;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #F9FAFB;
}

.quizPage-prequiz-subtitle {
    max-width: 80%;

    padding: 4px 8px 4px 8px;
    margin: 0 0 8px 0;

    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;

    text-align: center;
    text-transform: uppercase;

    color: #080D18;
    background: #2BCEF0;
}

.quizPage-prequiz-image {
    position: absolute;
    height: 30%;
    width: 80%;

    top: 35%;

    margin: 0 16px;

    background-image: url('../../style/assets/quiz/quiz.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.quizPage-prequiz-bottom {
    position: absolute;
    width: 80%;
    height: 30%;

    bottom: 0%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizPage-prequiz-desc {
    width: 100%;

    margin: 0 0 8px 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: #F9FAFB;
}

.quizPage-endquiz-desc {
    width: 100%;

    margin: 16px 0 8px 0;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 72px;
    line-height: 72px;

    text-align: center;
    letter-spacing: -2px;

    color: #FFFFFF;

    text-shadow: 0px 20px 32px rgba(17, 24, 39, 0.24), 0px 12px 12px rgba(17, 24, 39, 0.16);
}

.quizPage-prequiz-button {
    width: min(calc(100% - 32px), calc(100vh * 750/1624 - 32px));

    margin: 0.6em 0 0 0;

    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    background: #F9FAFB;
    box-shadow: 0px 4px 0px #D1D5DB;
    border-radius: 1px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizPage-prequiz-buttonText {
    height: 100%;

    right: 0%;
    margin: 14px 14px 14px 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
}

.quizPage-quizTop {
    position: relative;
    z-index: 90100;
    width: 100%;

    height: auto;

    /*background: linear-gradient(180deg, rgba(35, 41, 71, 1) 0%, rgba(35, 41, 71, 1) 40%, rgba(0, 0, 0, 0) 41%, rgba(0, 0, 0, 0) 100%);*/
    background-position: center 100%;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.quizPage-endQuizTop {
    position: relative;
    z-index: 90100;
    width: 100%;

    height: auto;

    margin: 0 0 32px 0;

    /*background: linear-gradient(180deg, rgba(35, 41, 71, 1) 0%, rgba(35, 41, 71, 1) 40%, rgba(0, 0, 0, 0) 41%, rgba(0, 0, 0, 0) 100%);*/
    background-position: center 100%;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.quizPage-quizTop-number {
    margin: 32px 0 2% 0;

    font-family: 'TungstenNarrow-Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;

    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #F9FAFB;
}

.quizPage-quizTop-text {
    margin: 0 0 32px 0;

    width: 80%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;

    text-align: center;
    letter-spacing: .04rem;

    color: #FFFFFF;
}

.quizPage-quizImg {
    z-index: 90000;
    width: 100%;
    height: fit-content;

    margin: 0 0 30vh 0;

    background-position: center 40%;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizPage-quizBlur {
    position: relative;
    height: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;

    text-align: center;

    color: #FFFFFF;
}

.quizPage-quizAnswer {
    font-family: 'TungstenNarrow-Semibold';
    font-style: normal;
    font-weight: 373;
    font-size: clamp(20px, 8vw, 80px);
    line-height: 42px;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 20px 32px rgba(17, 24, 39, 0.24), 0px 12px 12px rgba(17, 24, 39, 0.16);
}

.quizPage-quizExplanations {
    width: calc(100% - 32px);

    font-size: clamp(10px, 4.2vw, 80px);
}

.quizPage-quizBot::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;

    bottom: 0px;
    left: 0px;

    padding-bottom: 16px;

    background: rgb(8, 13, 24);
    background: linear-gradient(0deg, rgba(8, 13, 24, 1) 0%, rgba(8, 13, 23, 0.9051995798319328) 70%, rgba(8, 13, 23, 0) 100%);
}

.quizPage-quizBot {
    position: fixed;
    z-index: 90100;
    width: 100%;
    height: 30%;

    padding-bottom: 16px;
    bottom: 60px;

    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quizPage-quizBot-button {
    position: relative;
    width: min(calc(100% - 32px), calc(100vh * 750/1624 - 32px));
    height: 48px;

    margin: 0.8em 0 0 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: #1F2937;

    border: 1px solid #374151;

    box-shadow: 0px 4px 0px #1F2937;
    border-radius: 1px;
}

.quizPage-quizBot-buttonSelected {
    background: #F9FAFB;

    border: 0px;
    box-shadow: 0px 4px 0px #D1D5DB;
    border-radius: 1px;
}

.quizPage-quizBot-buttonText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;

    text-align: center;

    color: #F9FAFB;
}

.quizPage-quizBot-buttonTextSelected {
    color: #080D18;
}

.quizPage-rightAnim {
    position: absolute;
    z-index: 91000;
    height: 32px;
    width: 100%;

    top: -20%;
    transform: translateY(-50%);
}

.quizPage-wrongAnim {
    position: absolute;
    z-index: 91000;
    height: 32px;
    width: 100%;

    top: -20%;
    transform: translateY(-50%);
}

.quiz-arrowBot {
    position: fixed;
    z-index: 90200;
    bottom: 40vh;
    width: 36px;
    height: 36px;

    left: calc(50% - 16px);

    background-image: url(../../style/assets/downArrow.png);
    background-size: 28px;
    background-position: center 80%;
    background-repeat: no-repeat;

    filter: invert(1);
    /*backdrop-filter: opacity(0.2);
    border-radius: 24px;*/
}
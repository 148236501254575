html,
body,
#root {
	margin: 0;
	padding: 0;
	overscroll-behavior: none;
	width: 100%;
	height: 100%;
	position: fixed;
	overflow: hidden;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overscroll-behavior-y: contain;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'TungstenNarrow-Semibold';
	src: url('style/fonts/TungstenNarrow-Semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('style/fonts/Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('style/fonts/Poppins-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('style/fonts/Poppins-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

.texteDescription {
	-webkit-transition: transform 1000ms;
	-webkit-transition: -webkit-transform 1000ms;
	transition: all 1000ms;
}

.noSelect {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}

.App {
	width: 100%;
	height: 100%;
}

.clickable {
	user-select: none;
	cursor: pointer;
}

.disableNaturalClick {
	pointer-events: none;
}
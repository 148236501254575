.magnicity {
    position: absolute;
    height: 100%;
    width: 100%;

    background-color: #080B18;
}

.magnicity-welcome {
    z-index: 25000;
    position: absolute;
    height: 20%;

    top: 10%;
    left: 50%;
    transform: translateX(-50%);

    margin: 0 0 0 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    transition: all 2s;
}

.magnicity-welcome-text {
    margin: 4px;

    font-family: 'TungstenNarrow-Semibold';
    font-style: normal;
    font-weight: 373;
    font-size: 40px;
    line-height: 32px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.magnicity-welcome-place {
    margin: 4px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    /* identical to box height, or 100% */

    letter-spacing: -0.5px;

    /* UI/Berlin Rose/red-light */

    color: #F30C69;
    text-transform: uppercase;
}

.magnicity-welcome-towerName {
    margin: 4px;
}

.magnicity-welcome-towerNameContent {
    margin: 8px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */


    /* UI/CoolGray/950 */

    text-align: center;
    color: #080D18;
    text-transform: uppercase;
}

.sensors {
    display: none;
    opacity: 0;
}

.audiobg {
    z-index: 150000;
    position: absolute;
    height: 0px;
    width: 0px;
    pointer-events: none;
}

.changeOrientation {
    position: absolute;
    z-index: 130000;
    width: 100%;
    height: 100%;

    top: 0px;
    left: 0px;

    background-color: #080B18;
    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    opacity: 0;
    pointer-events: none;
}

@media only screen and (hover: none) and (pointer: coarse) and (orientation: landscape) {

    /*@media screen and (orientation: landscape) {*/
    .changeOrientation {
        opacity: 1;
    }
}

.compass {
    width: 600px;
    left: calc(50% - 300px);
    transform: perspective(1000px) translateY(-400px) translateZ(200px) rotateX(-65deg);
}

@media screen and (min-width: 600px) {
    .compass {
        width: 600px;
        left: calc(50% - 300px);
    }
}

.cgu-topContainer {
    z-index: 40000;
    position: absolute;
    height: calc(100% - 60px);
    width: 100%;

    top: 0px;
    left: 0px;

    background-color: #080D18;

    overflow-y: auto;
    overflow-x: hidden;
}

.cgu-textSubtitle {
    position: relative;

    margin: 72px 0 0 0;
    width: calc(100%);

    text-align: center;


    font-family: 'TungstenNarrow-Semibold';
    font-weight: 600;
    font-size: 48px;
    color: #F9FAFB;
    text-transform: uppercase;
    line-height: 48px;
}

.cgu-textBold {
    margin: 32px 0 0 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #F9FAFB;
}

.cgu-text {
    width: calc(100% - 48px);
    margin: 16px 0 16px 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F9FAFB;
}

.cgu-update {
    width: calc(100% - 48px);
    margin: 64px 0 16px 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #F9FAFB;
    text-align: end;
}